<template lang="pug">
  div
    slot(name="activator" :toggle="toggle")
      v-btn(icon :color="item.zoom_webinar_id ? 'white': 'primary'" x-small @click="toggle")
        v-icon(size="20" v-if="!item.zoom_webinar_id" color="red darken-1") mdi-video-box-off
        v-icon(size="20" v-if="item.zoom_webinar_id" color="primary darken-1") mdi-video-box


    app-dialog(v-model="showing" scrollable width="400" :title="title")
      template(#dialog-body)
        .set-zoom-id__wrapper
          app-input(v-model.number="zoomWebinarID" type="number" placeholder="Type zoom webinar ID here")
      template(#dialog-footer)
        confirmation-dialog(
          v-if="item.zoom_webinar_id"
          v-model="removeModal"
          @okBtnClicked="remove"
          width="420px"
          title="Removing Zoom ID"
          descr="Are you sure you want to remove zoom ID?"
        )
        v-btn(color="error" icon @click="removeModal = true" v-if="item.zoom_webinar_id")
          v-icon mdi-delete
          v-spacer
        v-spacer
        app-button(color="#f4f7f9" hide-shadow :disabled="loading" @click.native="toggle").mr-3
          span.text-label Close
        app-button(color="primary" :disabled="loading" @click.native="submit(zoomWebinarID)") Save
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin';
import showingMixin from '@/mixins/showing.mixin';
import apiCourses from '@/services/api/api-courses.js';
import courseService from '@/app/admin/models/courseService.js';

export default {
  name: 'CourseDaySetZoomID',

  mixins: [errorsMixin, showingMixin],

  props: {
    item: Object
  },

  computed: {
    title() {
      return this.item.zoom_webinar_id ? 'Edit Zoom Webinar ID' : 'Set Zoom Webinar ID'
    },
    btnApply() {
      return this.item.zoom_webinar_id ? 'Save' : 'Create'
    },
  },

  data: () => ({
    svc: new courseService(apiCourses),
    removeModal: false,
    loading: false,
    zoomWebinarID: null
  }),

  mounted() {
    this.setZoomIDData()
  },

  methods: {
    changeShowing(isShowing) {
      this.showing = isShowing
    },

    async submit(zoomID) {
      try {
        this.loading = true;
        if (!this.zoomWebinarID) return this.$notify({text: 'Please, fill field before sent', type: 'error'});
        await this.svc.updateCourseDayZoomID(this.item.id, zoomID)
        this.$notify({text: 'Success', type: 'success'});
        this.changeShowing(false);
        this.reset();
        this.$emit('update');
        this.loading = false;
      } catch (error) {
        this.processError(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
        });
        return Promise.reject(error)
      } finally {
        this.loading = false;
      }
    },

    setZoomIDData() {
      this.zoomWebinarID = this.item.zoom_webinar_id || null
    },

    reset() {
      this.zoomWebinarID = null
    },

    async remove() {
      await this.submit(null);
    }
  },

  watch: {
    'showing'() {
      if (this.showing) this.setZoomIDData()
      else this.reset()
    }
  },

  components: {
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    appInput: () => import('@/components/global/actions/BaseInput.vue'),
  }
}
</script>

<style lang="scss">
.set-zoom-id__wrapper {
  padding: 10px;
}
</style>